import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../../utils/api';

const RegistrationsList = ({ authUser }) => {
  const role = authUser?.currentlyProgram?.role?.roleName?.toLowerCase();
  const currentlyBatchId = authUser?.currentlyProgram?.batch?.batchId || 1;

  let offeredProgram = authUser?.offeredProgram;
  if (role === 'admin') {
    offeredProgram = [
      {
        batch: {
          batchId: 1,
          batch: "8",
        }
      },
      {
        batch: {
          batchId: 2,
          batch: "9",
        }
      },
      {
        batch: {
          batchId: 3,
          batch: "10",
        }
      }
    ];
  }
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [page] = useState(searchParams.get("page") || 1);
  const [pageSize] = useState(searchParams.get("pageSize") || 25)
  const [batchId] = useState(searchParams.get("batch") || 3);
  const [divisionInstitutionsId, setDivisionInstitutionsId] = useState(searchParams.get("divisionInstitutionsId") || "");
  // const [statusAcceptanceProgramId, setStatusAcceptanceProgramId] = useState("1,2,3,4,5,6");
  const [statusAcceptanceProgramId, setStatusAcceptanceProgramId] = useState(searchParams.get("statusAcceptanceProgramId") || "1,2,3,4,5,6");

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const [institutions, setInstitutions] = useState([]);
  const [provInstitutions, setProvInstitutions] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [statusAcceptance] = useState([
    {
      id: 1,
      name: "Diproses Seleksi",
    },
    {
      id: 2,
      name: "Lolos ke Seleksi FGD/Wawancara",
    },
    {
      id: 3,
      name: "Ditolak",
    },
    {
      id: 4,
      name: "Diterima",
    },
    {
      id: 5,
      name: "Mengundurkan Diri",
    },
    {
      id: 6,
      name: "Aktif",
    }
  ]);

  useEffect(() => {
    const getInstitutions = async () => {
      const data = await api.adminGetInstitutions({
        filterBy: role,
        batchId,
      });
      setInstitutions(data);
    }

    const getProvInstitutions = async () => {
      const data = await api.adminGetProvinciesInstitutions({ batchId });
      setProvInstitutions(data);
    }

    getInstitutions();
    getProvInstitutions();
  }, [batchId, role]);

  useEffect(() => {
    if (!searchParams.has('batch')) {
      setSearchParams({ ...searchParams, batch: currentlyBatchId });
      navigate(`?page=${page}&pageSize=${pageSize}&batch=${currentlyBatchId}`)
    }

    const getRegistrations = async () => {
      setLoading(true);
      const data = await api.getRegistrations({ page, pageSize, batchId, divisionInstitutionsId, statusAcceptanceProgramId });
      setUsers(data);
      setLoading(false);
    }

    getRegistrations();
  }, [pageSize, page, batchId, divisionInstitutionsId, statusAcceptanceProgramId, searchParams, setSearchParams, navigate, currentlyBatchId]);

  const handleNavigateToPage = (page) => {
    setSearchParams({ ...searchParams, page });
    navigate(`?page=${page}&pageSize=${pageSize}&batch=${batchId}&divisionInstitutionsId=${divisionInstitutionsId}&statusAcceptanceProgramId=${statusAcceptanceProgramId}`);
  }

  const handlePageSize = (pageSize) => {
    setSearchParams({ ...searchParams, pageSize });
    navigate(`?page=${page}&pageSize=${pageSize}&batch=${batchId}&divisionInstitutionsId=${divisionInstitutionsId}&statusAcceptanceProgramId=${statusAcceptanceProgramId}`)
  }

  const handleBatchId = (batchId) => {
    setSearchParams({ ...searchParams, batch: batchId });
    navigate(`?page=${page}&pageSize=${pageSize}&batch=${batchId}`)
  }

  const handleDivisionInstitutionsId = (divisionInstitutionsId) => {
    setSearchParams({ ...searchParams, divisionInstitutionsId });
    navigate(`?page=${page}&pageSize=${pageSize}&batch=${batchId}&divisionInstitutionsId=${divisionInstitutionsId}&statusAcceptanceProgramId=${statusAcceptanceProgramId}`)
  }

  const handleStatusAcceptanceProgramId = (statusAcceptanceProgramId) => {
    setSearchParams({ ...searchParams, statusAcceptanceProgramId });
    navigate(`?page=${page}&pageSize=${pageSize}&batch=${batchId}&divisionInstitutionsId=${divisionInstitutionsId}&statusAcceptanceProgramId=${statusAcceptanceProgramId}`)
  }

  const onHandleDivisiByInstitutionId = async ({ id }) => {
    const data = await api.adminGetInstitutionsById({ id, batchId, filterBy: role });
    setDivisions(data.divisions);
  }

  const onHandleStylePriority = (value) => {
    switch (value) {
      case 1:
        return 'success';
      case 2:
        return 'primary';
      case 3:
        return 'secondary';
      case 4:
        return 'warning';
      default:
        return '';
    }
  }

  return (
    <div className='card'>
      <div className="card-body">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>Pendaftaran</h1>
          <div className="row">
            <div className="col-6">
              <select value={pageSize} onChange={(e) => handlePageSize(e.target.value)} class="form-select" aria-label="Default select example">
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="col-6">
              <select value={batchId} onChange={(e) => handleBatchId(e.target.value)} className="form-select" aria-label="Default select example">
                {offeredProgram?.map((value, key) => (
                  <option key={key} value={value.batch.batchId}>BATCH: {value.batch.batch}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className={`row row-cols-md-${role === 'admin' ? '4' : '3'} mb-3`}>

          {role === 'admin' && (
            <div className='col'>
              <label htmlFor="country" className="form-label">Provinsi</label>
              <select value={divisionInstitutionsId} onChange={(e) => handleDivisionInstitutionsId(e.target.value)} className="form-select" required>
                <option value="">Pilih</option>
                {provInstitutions.map((value, key) => (
                  <option key={key} value={value.divisionInstitutionsId}>{value.provinceName}</option>
                ))}
              </select>
            </div>
          )}

          <div className="col">
            <label htmlFor="country" className="form-label">Lembaga</label>
            <select onChange={(e) => onHandleDivisiByInstitutionId({ id: e.target.value })} className="form-select" required>
              <option defaultValue>Pilih Lembaga</option>
              {institutions.map((institution, key) => (
                <option key={key} value={institution.institutionsId}>{institution.institutionsName}</option>
              ))}
            </select>
          </div>

          <div className="col">
            <label htmlFor="country" className="form-label">Divisi</label>
            <select disabled={!divisions?.length > 0} onChange={(e) => {
              // <select multiple={true} size={!divisions.length > 0 ? 1 : divisions.length} disabled={!divisions?.length > 0} onChange={(e) => {
              const options = [...e.target.selectedOptions];
              const values = options.map(option => option.value);
              setDivisionInstitutionsId(values);
            }} className="form-select" required>
              {divisions?.map((division, key) => (
                <option key={key} value={division.institutionsDivisionId}>
                  {division.divisionName}
                  {' | Batch: '}
                  {division?.batch?.batch}
                </option>
              ))}
            </select>
          </div>

          <div className="col">
            <label htmlFor="country" className="form-label">Status Penerimaan</label>
            <select disabled={!statusAcceptance?.length > 0} onChange={(e) => {
              const options = [...e.target.selectedOptions];
              const values = options.map(option => option.value);
              setStatusAcceptanceProgramId(values);
            }} className="form-select" required>
              <option value={"1,2,3,4,5,6"}>Semua Mahasiswa</option>
              {statusAcceptance?.map((value, key) => (
                <option key={key} value={value.id}>{value.name}</option>
              ))}
            </select>
          </div>

        </div>

        <div className='table-responsive'>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Mahasiswa</th>
                <th scope="col">Program Studi</th>
                <th scope="col">Universitas</th>
                <th scope="col">IPK</th>
                <th scope="col">Provinsi Lembaga</th>
                <th scope="col">Divisi</th>
                <th scope="col">Domisili KTP</th>
                <th scope="col">Domisili Saat Ini</th>
                <th scope="col">CV</th>
                <th scope="col">Transkrip Nilai</th>
                <th scope="col">Prioritas</th>
                <th scope="col">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {loading ?
                <>
                  {Array.apply(null, Array(Number(pageSize))).map(() => (
                    <tr className='placeholder-glow'>
                      <th><span className='placeholder col-12'></span></th>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                      <td><span className='placeholder col-12'></span></td>
                    </tr>
                  ))}
                </>
                :
                <>
                  {users?.students?.map((value, key) => (
                    <tr key={key}>
                      <th scope="row">{(page - 1) * pageSize + (key + 1)}</th>
                      <td>{`${value.firstName || ''} ${value.lastName || ''}`}</td>
                      <td>{value.universities.universitiesMajor}</td>
                      <td>{value.universities.universitiesName}</td>
                      <td>{value.universities.gradePointAverage}</td>
                      <td>{value.institutions.institutionsLocation}</td>
                      <td>{value.institutions.institutionsDivisionName}</td>
                      {value.domicilies.map((value, key) => (
                        <td key={key}>
                          {value.provinceName} - {value.regenciesName}
                        </td>
                      ))}
                      <td>
                        <a href={`${value.curriculumVitae}`} target='_blank' rel="noreferrer" >
                          Lihat
                        </a>
                      </td>
                      <td>
                        <a href={`${value.academicTranscript}`} target='_blank' rel="noreferrer" >
                          Lihat
                        </a>
                      </td>
                      <td>
                        <span class={`badge rounded-pill text-bg-${onHandleStylePriority(value.priority.priorityValue)}`}>
                          {value.priority.priorityMessage}
                        </span>
                      </td>
                      <td>
                        <Link to={`/${role}/registrations/${value.CLPUsersId}`} className='btn btn-primary badge rounded-pill'>Detil</Link>
                      </td>
                    </tr>
                  ))}
                </>
              }
            </tbody>
          </table>
        </div>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(users.previous)} class={`page-link ${!users.previous && 'disabled'}`}>Previous</button>
            </li>
            <li class="page-item"><p class="page-link">{users.current + 1}</p></li>
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(users.next + 1)} class={`page-link ${!users.next && 'disabled'}`}>Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </div >
  )
}

export default RegistrationsList;
