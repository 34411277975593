import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// Register required Chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ data }) => {
  // Extract labels (dates) and values (counts) from data
  const labels = data.map((item) => item.created_date);
  const totalUsersData = data.map(item => item.total_users);
  const totalUsersClpData = data.map(item => item.total_users_clp);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Daftar",
        data: totalUsersData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        pointBorderColor: "#fff",
        pointBackgroundColor: "rgb(255, 99, 132)",
        tension: 0.4, // Smooth curve
      },
      {
        label: "Daftar Magang CLP",
        data: totalUsersClpData,
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        pointBorderColor: "#fff",
        pointBackgroundColor: "rgb(54, 162, 235)",
        tension: 0.4, // Smooth curve
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: true },
      title: { display: true, text: "User Registrations Per Day" },
    },
    scales: {
      x: { title: { display: true, text: "Date" } },
      y: { title: { display: true, text: "Number of Users" }, beginAtZero: true },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;
