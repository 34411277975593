import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ProgramIndex = () => {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const targetDate = new Date("2025-02-19T21:00:00");

      if (now >= targetDate) {
        setIsDisabled(true);
      }
    };

    checkTime();
    const interval = setInterval(checkTime, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  return (
    <div className='row'>
      <h3>Program Campus Leader Program <span class="badge text-bg-success">Batch 10</span></h3>

      <div className='row g-3'>

        <div className="col-12 col-md-4">
          <div class="card shadow">
            <div class="card-body">
              <h5 class="card-title">Magang</h5>
              <p class="card-text">Dapatkan pengalaman dunia kerja secara langsung sebagai persiapan kariermu.</p>
              <button disabled={isDisabled} onClick={() => navigate("/program/registrations")} class="btn btn-primary">Daftar</button>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Praktikum</h5>
              <p class="card-text">Dapatkan pengalaman dunia kerja secara langsung sebagai persiapan kariermu.</p>
              <button disabled to="/registrations" class="btn btn-primary">Coming Soon!</button>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Volunteer</h5>
              <p class="card-text">Dapatkan pengalaman dunia kerja secara langsung sebagai persiapan kariermu.</p>
              <button disabled to="/registrations" class="btn btn-primary">Coming Soon!</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ProgramIndex